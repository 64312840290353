.dealer-application-form {
  .form {
    overflow: hidden;
    @apply bg-white mt-4  mb-10;

    .form-div {
      @apply py-5 ml-6 mt-4 mr-6;

      .label2 {
        @apply flex flex-wrap flex-col md:flex-row -mx-3 mb-1;

        .label-div {
          @apply w-full md:w-1/2 px-3 mb-6 md:mb-0;
        }

        .label-text {
          @apply text-gray-400 text-sm;
        }
        .input {
          @apply appearance-none border py-3 px-3 pl-3 mt-3 w-full text-gray-700 leading-tight;
        }
      }
      .button-div {
        @apply flex items-center mt-16 mb-10;
      }
    }
  }

  .phoneInput input {
    line-height: 1.25 !important;
    height: 90% !important;
    font-size: 16px !important;
    background: #ffffff !important;
    border: 0.5px solid #cacaca !important;
    width: 100% !important;
    outline: none !important;
    transition: box-shadow ease 0.25s, border-color ease 0.25s !important;
    padding-top: 14px !important;
    padding-bottom: 12px !important;
    border-radius: 0 !important;
  }

  .select-dropdown {
    margin-top: 12px;
    border-radius: 1 !important;
  }
  .dropdown-box {
    margin-top: 12px;
    background-color: white;
    border-radius: 1px;
    padding: 2px;
    border-width: 1px;
  }
}

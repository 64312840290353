.po-tab-menu.tabs {
  .tab-list {
    @apply flex flex-wrap;
  }

  .tab-list-item {
    @apply cursor-pointer hover:text-black text-center py-2 text-sm;
    color: $spanish-gray;
    border-bottom: 4px solid $anti-flash-white;
    padding-left: 0.65rem;
    padding-right: 0.65rem;
  }

  .tab-list-active {
    @apply font-semibold;
    border-bottom: 4px solid $primary-color;
    color: #4b124b !important;
  }

  .tab-list-item-ph {
    @apply flex-grow;
    border-bottom: 4px solid $anti-flash-white;
  }
}
.tab-color {
  background-color: $primary-color;
}

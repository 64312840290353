.feature-title {
  @apply font-semibold absolute -top-3.5 z-10 bg-white px-2;
  color: $dark-grey-1;
}

.feature-value {
  @apply rounded border px-2 py-1 bg-white cursor-pointer;
  color: $dark-grey-1;
}

.spec-title {
  @apply font-semibold;
  color: $dark-grey-1;
}

.bg-color-tabs {
  background-color: #eee;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.wrap-balloon-percentage {
  @apply w-32 flex flex-wrap;
  text-wrap: wrap;
}

.po-dashboard {
  .dashboard-card {
    div {
      border-radius: 4px;
    }
    .text-color {
      color: $primary-color;
    }
  }

  .pie-chart-div {
    border-radius: 4px;
    width: 24rem;
  }
}

.favorite-report {
  @apply border-0 rounded shadow-lg text-primary font-bold h-20 flex items-center justify-center cursor-pointer text-lg;
  :hover {
    background: $btn-hover;
    color: $primary-color;
    border-color: $btn-hover;
    @apply border w-full h-full flex items-center justify-center;
  }
}

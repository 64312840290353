.po-packages {
  .po-packages-frequency {
    .frequency-list-item {
      @apply p-4 flex justify-center w-full cursor-pointer;
      background-color: $independence;
      &.active {
        background-color: $cadmium-orange;
      }
    }
    .triangle {
      display: flex;
      height: 0;
      width: 0;
      border: 10px solid transparent;
      border-top-color: $cadmium-orange;
      left: calc(50px - 10px);
      background: transparent;
    }
  }
  .description {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    overflow-wrap: normal;
  }

  .bg-success {
    background-color: $success;
  }

  .success-icon-bg {
    color: $success;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 65px;
    height: 100%;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;

    &::-webkit-color-swatch-wrapper {
      padding: 0 !important;
    }
    &::-webkit-color-swatch {
      border: none !important;
    }
  }
  .package-border {
    border: 1px solid $light-grey;
  }
  .package-border-r {
    border: 1px solid $light-grey;
    border-right-width: 0px;
  }
}

.profile-icons {
  height: 15px;
  width: 20px;
}

.profile-select {
  height: 26px;
  width: 26px;
}

.icons-position {
  display: flex;
  flex-direction: row;
  padding: 5px;
  color: rgb(156, 163, 175);
  font-weight: 550;
}

.dot {
  height: 30px;
  width: 30px;
  background-color: #454d45;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  left: 4px;
}

.logout-border {
  border-top: 1px solid;
  border-top-color: rgb(235, 235, 238);
  width: 93%;
  margin: auto;
}

.divide-y {
  & > :not([hidden]),
  & ~ :not([hidden]) {
    --tw-divide-y-reverse: none !important;
    border-top-width: none !important ;
  }
}

.divide-y {
  & > :not([hidden]),
  & ~ :not([hidden]) {
    --tw-divide-opacity: none !important;
    border-color: white !important;
  }
}

.logout-label {
  margin-left: 5px;
}

.bookmarks {
  margin-top: -4px;
}

.dropdown-button {
  position: relative;
  top: 5px;
  left: 5px;
}

.origin-top-right {
  position: "absolute";
  z-index: 10;
}

@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Vazirmatn&display=swap");

@font-face {
  font-family: "century-gothic";
  src: url("../../assets/centuryGothic/Century Gothic-Regular.TTF");
}

@font-face {
  font-family: "century-gothic";
  src: url("../../assets/centuryGothic/Century Gothic-Bold.TTF");
  font-weight: bold;
}

@font-face {
  font-family: "Tahoma";
  src: url("../../assets/tahoma/Tahoma-Regular.TTF");
}

@font-face {
  font-family: "Tahoma";
  src: url("../../assets/tahoma/Tahoma-Bold.TTF");
  font-weight: bold;
}

$font-family: "Merriweather Sans", century-gothic, sans-serif;
$font-family-ar: "Vazirmatn", Tahoma, sans-serif;

$primary-color: #4c0055;
$rusty-red: #dc323a;
$red: #ff0000;
$green: #00ff00;
$white: #ffffff;
$black: #000000;
$anti-flash-white: #f2f4f5;
$gray: #7b7b7b;
$charcoal: #374151;
$independence: #465166;
$alice-blue: #f4f7fc;
$cadmium-orange: #f29232;
$light-silver: #d9d9d9;
$columbia-blue: #d0d7e2;
$spanish-gray: #989898;
$granite-gray: #626662;
$raisin-black: #242424;
$kelly-green: #48bc00;
$misty-rose: #ffe1e2;
$light-coral: #eb7f82;
$roman-silver: #7d8593;
$kiwi: #72d336;
$tomato: #f76542;
$medium-turquoise: #37d0ca;
$satin-sheen-gold: #d0a137;
$platinum: #e8e6e6;
$bright-gray: #ebebeb;
$transparent: rgba(0, 0, 0, 0);
$orioles-orange: #fa4210;
$dark-grey-2: #929597;
$dark-grey-1: #43474d;
$success: #824c88;
$error: #de2a00;
$light-grey: #e0e0e0;
$selection: #fe5000;
$warning: #ff8300;
$info: #d2bfd5;
$dark-purple: #17031a;
$dark-grey-3: #c7c7c7;

$body-background: $anti-flash-white;
$body-text: $gray;

$btn-primary: $primary-color;
$btn-hover: #b799bb;
$btn-grid-primary: $primary-color;
$btn-grid-secondary: #465166;
$btn-grid-green: #2fa84f;
$btn-grid-text-color: #fff;
$btn-disabled-primary: #e0e0e0;

button.btn,
.btn {
  @apply text-center text-white cursor-pointer text-base font-semibold shadow-md py-1.5 px-2 rounded hover:shadow-xl;
  min-width: 87px;

  &.btn-primary {
    background: $btn-primary;
    border-color: $btn-primary;
    @apply text-white hover:shadow-lg border;
    &:hover {
      background: $btn-hover;
      color: $primary-color;
      border-color: $btn-hover;
      @apply border;
    }
  }

  &.btn-cancel,
  &.btn-secondary {
    color: $btn-primary;
    border-color: $btn-primary;
    @apply bg-white border;
    &:hover {
      background: $btn-hover;
      color: $primary-color;
      border-color: $btn-hover;
      @apply border;
    }
  }
  &.btn-gradient {
    background-image: linear-gradient(to right, #ff8300, #de2a00);
  }

  &.btn-disabled-primary {
    background: $btn-disabled-primary;
    color: $dark-grey-2;
    cursor: not-allowed;
  }

  &.btn-disabled-cancel,
  &.btn-disabled-secondary {
    color: $dark-grey-2;
    border-color: $dark-grey-2;
    @apply border;
  }
  &.btn-success {
    background: $kelly-green;
    border-color: $kelly-green;
    @apply text-white hover:shadow-lg border;
  }

  &.btn-sm {
    @apply text-xs p-2;
  }

  &.btn-grid-primary {
    @apply text-xs p-2 focus:outline-none leading-none border;
    background: $btn-grid-primary;
    color: $btn-grid-text-color;
  }

  &.btn-grid-secondary {
    @apply text-xs p-2 focus:outline-none leading-none border;
    background: $btn-grid-secondary;
    color: $btn-grid-text-color;
  }

  &.btn-grid-green {
    @apply text-xs p-2 focus:outline-none leading-none border;
    background: $btn-grid-green;
    color: $btn-grid-text-color;
  }
}

.btn-dropdown {
  border-color: $btn-primary;
  background: $btn-primary;
  @apply text-white hover:shadow-lg border py-1.5 px-2;
  &:hover {
    background: $btn-hover;
    color: $primary-color;
    border-color: $btn-hover;
    @apply border;
  }

  &.btn-expand {
    background-color: $dark-purple;
  }
}

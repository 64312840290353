.paraContent {
  color: #7d8593;
  letter-spacing: 1px;
}

.paraHeading {
  color: #465166;
}

.paraHeadingContainer {
  padding-bottom: 5px;
}

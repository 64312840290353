.po-toggle {
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;

    input[type="checkbox"] {
      display: none;
    }

    .switch {
      position: absolute;
      cursor: pointer;
      background-color: gray;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;

      &::before {
        position: absolute;
        content: "";
        left: 2px;
        top: 2.5px;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
    }

    input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      background-color: white;
    }

    input[type="checkbox"]:checked + .switch {
      background-color: $primary-color;
    }
  }

  .label-width {
    min-width: 60px;
  }
}

.vehicle-wizard {
  .text-dark-gray-1 {
    color: $dark-grey-1;
  }
  .feature-btn {
    color: $dark-grey-1 !important;
    border-color: $light-grey !important;
  }
  .bg-vehicle-wizard-tabs {
    background-color: #eeeeee;
  }
  .color-variant-dropdown {
    overflow: hidden;
  }
  .color-variant-dropdown select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}

.bulk-upload {
  .upload-vehicle-input {
    border-radius: 2px;
    width: 100%;
    height: 38px;
    padding: 3px;
    border: 1px solid gray;
  }

  .label-text {
    @apply text-gray-500 font-semibold;
    font-size: 18px;
  }

  .label-text-1 {
    @apply font-semibold;
    font-size: 18px;
    color: black;
  }

  .label-text-2 {
    @apply text-gray-500;
    font-size: 15px;
  }

  .label-text-3 {
    @apply text-gray-500;
    font-size: 15px;
  }

  .btn-grey {
    @apply p-3 w-24 text-white text-sm mt-1 mr-5 cursor-pointer;
    background-color: rgb(168, 164, 164);
  }

  .input-file {
    display: none;
  }

  .btn-cancel {
    @apply bg-white hover:bg-red-700 hover:text-white text-red-500 border border-red-500 font-bold;
  }

  .label-text {
    @apply text-gray-500 font-semibold;
    font-size: 18px;
  }

  .label-text-1 {
    @apply font-semibold;
    font-size: 18px;
    color: black;
  }

  .label-text-2 {
    @apply text-gray-500 font-semibold;
    font-size: 15px;
  }

  .label-text-3 {
    @apply text-gray-500;
    font-size: 15px;
  }

  .btn-grey {
    @apply rounded p-3 w-24 text-white text-sm mt-1 mr-5 cursor-pointer hover:bg-red-500;
    background-color: rgb(168, 164, 164);
  }

  .input-file {
    display: none;
  }

  .ok-button-bulk {
    @apply bg-red-500 px-4 py-2 hover:text-white text-white border border-red-500 font-bold;
  }

  .btn-cancel {
    @apply bg-white hover:bg-red-700 hover:text-white text-red-500 border border-red-500 font-bold;
  }

  .modalContainer {
    width: 450px;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
  }

  .modalBackground {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bulk-upload-table {
    table {
      border: 0.2px solid rgb(185, 180, 180);
      margin-bottom: 10px;
      font-size: 12px;
      width: 100%;
    }
    th {
      border: 0.2px solid rgb(185, 180, 180);
      margin-bottom: 20px;
      padding: 10px;
      background-color: rgb(250, 235, 235);
      text-align: left;
    }
    td {
      border: 0.2px solid rgb(185, 180, 180);
      margin-bottom: 20px;
      padding: 10px;
    }
  }

  /*used in report by bulk id page*/
  .scroll-listing-sum {
    overflow: auto !important;
  }

  .break-report-data {
    max-width: 12rem;
    word-wrap: break-word;
  }
}

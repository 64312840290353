.po-form-input-counter {
  display: flex;
  align-items: center;
  resize: both;

  input {
    appearance: none;
    border-width: 1px;
    color: $charcoal;
    width: 45%;
    padding: 7%;
    margin: 0% 5%;
    text-align: center;
    margin: 5% 10%;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .counter-button {
    font-size: 25px;
  }
}

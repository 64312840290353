.po-slider {
  img {
    @apply object-cover w-full;
    aspect-ratio: 4/3;

    &.image-list-item {
      aspect-ratio: 4/3;
    }
  }
  .main-image {
    .swiper-button-next,
    .swiper-button-prev {
      color: white;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 15px;
      padding: 25px;
    }

    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after,
    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
      font-size: 30px;
    }
  }

  .image-list {
    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after,
    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
      font-size: 20px;
      font-weight: bold;
    }
    border-bottom-left-radius: 20px;

    .swiper-button-next,
    .swiper-button-prev {
      color: rgb(15, 12, 12);
      background-color: rgb(207, 204, 207);
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }

  &:hover {
    .main-image {
      .swiper-button-next,
      .swiper-button-prev {
        background: rgba(91, 90, 90, 0.5);
      }
    }
  }
  .featured-icon {
    position: absolute;
    margin-top: 0.25rem;
    height: 15%;
    top: 1rem;
    right: 1rem;
  }
}

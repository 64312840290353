.app.app-rtl {
  .ar-header-menu,
  .header-menu,
  .dashboard-arabic-view,
  .arabic {
    flex-direction: row-reverse;
  }

  .buttons-arabic {
    display: flex;
    justify-content: flex-end;
  }
  .QC-form-container {
    margin-right: 15px;
  }
  .export-button-arabic {
    display: flex;
    justify-content: flex-start;
  }
  .package-arabic {
    display: flex;
    flex-direction: row-reverse;
  }
  .recharts-legend-item {
    display: flex !important;
    flex-direction: row-reverse;
    gap: 5px;
  }
  .rating-arabic {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  .card-detail-desc {
    @apply pr-4;
  }
  @media (max-width: 768px) {
    .dashboard-arabic-view {
      flex-direction: column;
    }
    .feedback {
      .recharts-legend-item {
        display: inline-block !important;
        direction: rtl;
      }
    }
  }
  @media (min-width: 640px) {
    .feedback-line-chart {
      @apply absolute flex-col gap-x-0 top-32 left-28;
    }
  }
  @media (min-width: 768px) {
    .tile-round {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .image-round {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .arabic-items-left {
    display: flex;
    justify-content: flex-start;
  }

  .arabic-items-right {
    display: flex;
    justify-content: flex-end;
  }

  .arabic-text-right {
    text-align: right;
  }

  .arabic-direction {
    direction: rtl;
  }

  .ar-notification-count {
    left: -17px !important;
  }

  .ar-notification-count-2 {
    left: -18px !important;
  }

  .vehicle-age-body {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .arabic-back-icon {
    transform: scale(-1, 1);
    direction: ltr;
  }
  .arabic-forward-icon {
    transform: scale(-1, 1);
    direction: rtl;
  }

  .phone-input-flag-icon {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .card-action-ar {
    @apply p-3;

    &.checkbox-container {
      input {
        padding: 0;
        margin-bottom: 0;
        display: none;
        cursor: pointer;

        &:checked + label:after {
          content: "";
          display: block;
          position: absolute;
          top: 2px;
          right: 13px;
          width: 6px;
          height: 14px;
          border: solid #4b164b;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }

      label {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        cursor: pointer;
        color: #4b164b;

        &:before {
          content: "";
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid #4b164b;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-left: 5px;
          top: -1px;
        }
      }
    }
  }

  .direction-ltr {
    direction: ltr;
  }

  .ar-header-profile-dropdown-button {
    left: 0px;
  }
  .ar-search-icon {
    left: 1rem !important;
    right: auto !important;
  }

  .quotation-new-icon {
    top: 0px;
    right: 0px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables";
@import "./common";
@import "./components";
@import "./views";
@import "./rtl";

html {
  font-size: 14px;
}

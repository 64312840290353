.po-vehicle-status-step {
  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .stepper-item {
    position: relative;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    top: 12px;
    left: -90%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 12px;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }
  .bg-success {
    color: $success;
  }
  .bg-error {
    color: $error;
  }
  .text-dark-gray-2 {
    color: $dark-grey-2;
  }

  .stepper-item.active {
    font-weight: bold;
  }

  .stepper-item.completed .step-counter {
    background-color: $light-grey;
  }

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    width: 100%;
    top: 12px;
    z-index: 3;
    border: 0;
    border-top: dashed 1px $dark-grey-2;
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  .step-name {
    width: 50%;
  }

  .form-textarea {
    height: 120px;
  }
}

/*App header css*/
.icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 8px;
}
.header-top {
  .input-wrapper {
    display: flex;
    align-items: center;
    .icon {
      fill: #7d8593;
    }
    input {
      border: none;
      font-size: 13px;
      outline: none;
    }
  }
}
.list-wrapper {
  width: 100%;
  li {
    padding: 0 6px;

    &:last-child {
      border-right: none;
    }
    a {
      div {
        display: inline-block;
        z-index: 9999;
      }
    }
    a {
      color: #4b124b;
      font-size: 13px;
    }
    .mr-0 {
      margin-right: 0;
    }
  }
}
.header-top {
  padding: 4px 0;
  background: #fff;
  .header-top-container {
    padding-bottom: 2px;
    // border-bottom: 1px solid #f2f2f2;
  }
  .create-user-btn {
    background: #fdf3e9;
    color: #f29232;
    padding: 4px 15px;
    font-size: 13px;
    transition: all 0.2s ease-in;
    cursor: pointer;
    &:hover {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    }
  }
  .inactive {
    display: none;
  }
}
.notification-holder {
  .heading {
    display: flex;
    justify-content: space-between;
    padding: 7px 12px;
  }
  .notification-item {
    padding: 6px 12px;
  }
  .name {
    color: #181818;
    font-weight: 700;
    overflow: hidden;
    overflow-wrap: normal;
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .time {
    display: block;
  }
  .heading-title {
    text-transform: uppercase;
    font-size: 14px;
    font-family: $font-family;
  }
  .notification-link {
    color: #dc2c2b;
  }
}
@media (min-width: 992px) {
  .list-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header-top {
    .inactive {
      display: block;
    }
  }
}

header {
  background: #fff;
  .header-container {
    position: relative;
  }
  .logo-wrapper {
    // .m-logo {
    //   max-width: 100px !important;
    // }
  }
}
.icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 8px;
}

.link-holder {
  padding: 8px 0;
  color: #4b124b;
  a {
    display: block;
    padding: 8px 0px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in;
    color: #4b124b;
    font-size: 13px;
    font-weight: 700;
    &:hover {
      color: #fa4210;
    }
    &.is-active {
      color: white;
      padding: 6px;
      border: 1px solid #fa4210 !important;
      border-radius: 25px;
      background: #fa4210;
    }
  }
  button {
    transition: all 0.2s ease-in;
    color: #465166;
    font-size: 13px;
    font-weight: 700;
    &:hover {
      color: #dc323a;
    }
    &.is-active {
      color: #dc323a;
    }
    @apply mr-5 hover:text-red-600 text-sm;
  }
}

.sign-in-btn {
  width: 100%;
  padding: 16px 40px;
  background: #dc323a;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
}
.select-btn {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 16px 10px;
  color: #465166;
  select {
    background: transparent;
    color: #465166;
    outline: none;
    option {
      color: #333;
    }
  }
}
@media (min-width: 992px) {
  .sign-in-btn {
    width: auto;
    padding: 8px 20px;
  }
  .select-btn {
    width: auto;
  }
}
.z-999 {
  z-index: 9999;
}
.active {
  .nav-wrapper {
    display: block;
  }
}
.inactive {
  .nav-wrapper {
    display: none;
  }
}
.logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.active {
  width: 100%;
}
@media (min-width: 992px) {
  .link-holder {
    a {
      display: inline-block;
      font-size: 13px;
    }
    button {
      display: inline-block;
      font-size: 13px;
    }
  }
  .active {
    .nav-wrapper {
      display: block;
    }
  }
  .inactive {
    width: 100%;
    .nav-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .link-holder {
    @apply flex justify-center items-center;
    a {
      &:first-child {
        padding: 2px 6px;
        border: 1.5px solid #4b124b;
        border-radius: 25px;
        min-width: 100px;
      }
    }
    button {
      &:first-child {
        padding-left: 0;
      }
    }
  }
  header {
    .logo-wrapper {
      width: auto;
      position: absolute;
      left: 20px;
      top: 30px;
      // .m-logo {
      //   position: absolute;
      //   left: -130px;
      //   top: -40px;
      // }
    }
  }
}
@media (min-width: 1200px) {
  .link-holder {
    a {
      display: inline-block;
      font-size: 12px;
      text-align: center;
    }
    button {
      display: inline-block;
      font-size: 15px;
    }
  }
}

.direction-rtl {
  direction: rtl;
}
@media (min-width: 992px) {
  .border-right-li {
    border-right: 1px solid #ccc;
  }
  .border-left-li {
    border-left: 1px solid #ccc;
  }
}

.bell-notification {
  .EmailEditorTheme__paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      white-space: nowrap !important;
    }
  }
}

@media (min-width: 1024px) {
  a {
    &.is-active {
      border-bottom: 4px solid #4b124b;
    }
  }
}

.po-my-vehicle {
  .grid-list-view {
    @apply grid grid-flow-row grid-cols-3 gap-3;
  }
  @media (max-width: 768px) {
    .grid-list-view {
      @apply grid-cols-2;
    }
    .grid-vehicle-img {
      width: 100%;
      height: 220px;
    }
  }
  @media (max-width: 480px) {
    .grid-list-view {
      @apply grid-cols-1;
    }
  }
  @media (min-width: 768px) {
    .grid-vehicle-img {
      width: 100%;
      height: 180px;
    }
  }
  .grid-checkbox-view input {
    border: 1px solid #f29232;
  }
  .grid-checkbox-view label {
    color: #f29232;
  }
  .checkbox-container input {
    padding: 0;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  .checkbox-container label {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    color: #f29232;
  }
  .checkbox-container label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #f29232;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
  }
  .checkbox-container input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;
    width: 6px;
    height: 14px;
    border: solid #f29232;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .clear-filter-button {
    padding-right: 10px;
    text-decoration: underline;
    color: #dc323a;
    font-weight: 600;
    padding-top: 5px;
    cursor: pointer;
  }
  .filter-box {
    background-color: #fff;
    border-radius: 4px;
    color: #7d8593;
  }
  .notify-me-section {
    background-color: #fff;
    color: #7d8593;
    padding: 12px;
    border-radius: 20px;
  }
  .notify-me-section span {
    color: #000;
    font-weight: 600;
    text-decoration: underline;
  }

  .dealer-logo-img-myvehicle {
    position: absolute;
    bottom: 79%;
    left: 1%;
    width: 11%;
    height: 20%;
  }

  .ga-vehicle-detail {
    border-radius: 4px;
  }

  .featured-add-icon {
    height: 35px;
    width: 120px;
  }
}

.list-vehicle-view {
  .card-detail {
    @apply flex m-2 mb-0;

    .img-container {
      @apply w-48 flex-none bg-cover text-center cursor-pointer;
      position: relative;

      img.dealerLogoImage {
        @apply bottom-6 absolute;
        margin-bottom: 0.1rem;
        width: 30%;
      }

      img.vehicleImage {
        @apply w-48 border object-cover;
        aspect-ratio: 4/3;
      }

      .featured-icon-container {
        position: absolute;
        height: 20%;
        right: 7px;
      }
      .feature-text {
        font-size: 9px;
      }
    }

    .card-detail-desc {
      @apply pl-4 flex flex-col justify-between leading-normal w-full;

      .card-header {
        @apply mb-2 tracking-wide  text-base font-semibold flex justify-between;
        color: $primary-color;
        .card-header-title {
          @apply flex items-center;
        }
        .vehicle-header-wrapping {
          display: flex;
          word-break: break-word;
          max-width: 400px;
        }

        .card-header-link {
          @apply flex flex-row;
          .link {
            @apply mr-2 text-sm;
            &:last-child {
              @apply mr-0;
            }
          }
        }
      }

      .card-sm-badges {
        @apply flex items-center my-2;

        .badge-img-lbl {
          @apply mr-2 text-sm ml-3 text-gray-400 flex;
        }
      }
    }

    .grid-action {
      @apply hidden;
    }
  }

  .card-summary {
    @apply flex justify-between m-2;

    .card-summary-container {
      @apply flex flex-row flex-grow w-full flex-wrap;

      .box {
        @apply flex flex-col px-4;
      }

      .card-action {
        @apply flex flex-col justify-center px-4;

        .action-link {
          @apply text-primary font-bold text-base cursor-pointer underline;
        }
      }
    }

    .view-more {
      @apply flex flex-grow-0;
    }
  }

  .card-action {
    @apply p-3;

    &.checkbox-container {
      input {
        padding: 0;
        margin-bottom: 0;
        display: none;
        cursor: pointer;

        &:checked + label:after {
          content: "";
          display: block;
          position: absolute;
          top: 2px;
          left: 8px;
          width: 6px;
          height: 14px;
          border: solid $primary-color;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }

      label {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        cursor: pointer;
        color: $primary-color;

        &:before {
          content: "";
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid $primary-color;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          top: -1px;
        }
      }
    }
  }
  .checkbox-class {
    height: 20px;
    accent-color: $primary-color;
    width: 20px;
    cursor: pointer;
  }
  .checkboxLabel {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    color: $primary-color;
  }

  &.vehicle-card-grid {
    .card-detail {
      @apply flex-col;

      .img-container {
        @apply w-full;

        img.dealerLogoImage {
          position: absolute;
          margin-bottom: 0.5rem;
          width: 25%;
          bottom: 0;
        }

        img.vehicleImage {
          @apply w-full border object-cover;
          aspect-ratio: 4/3;
        }
      }

      .card-detail-desc {
        @apply pl-0 pt-2;
        .card-header {
          .card-header-title {
            @apply flex flex-grow items-center cursor-pointer;
          }

          .card-header-link {
            @apply flex-col;

            .link {
              @apply mr-0 mb-2;
            }
          }
        }

        .card-sm-badges {
          @apply flex-wrap my-4 items-start;

          .badge-img-lbl {
            @apply text-sm text-gray-400 flex w-1/2 m-0;
          }
        }
      }
    }
    .card-summary {
      @apply flex justify-between m-2 my-4;

      .card-summary-container {
        @apply flex-wrap pr-0;

        .card-action {
          @apply flex flex-col justify-center px-4 py-2 w-1/2 text-center;

          &.hide {
            @apply hidden;
          }
        }

        .box {
          @apply w-1/2;
        }
      }

      .view-more {
        @apply flex-none hidden;
      }
    }
    .grid-action {
      @apply flex w-full justify-between bg-gray-200;
    }
  }
}

.package-selection {
  .slider {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 6px;
    }

    ::-webkit-scrollbar:horizontal {
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      @apply rounded-md;
      background-color: $dark-grey-3;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #eeeeee;
    }
  }
  .package-shadow {
    box-shadow: -8px 4.81px 45px 0px rgba(0, 0, 0, 0.15) !important;
  }
  .select-button {
    width: 8.5rem !important;
    border-radius: 0.5rem !important;
  }
  .card-height {
    height: 30rem;
  }
  .package-background-gradient-color {
    // background: linear-gradient(
    //   to left,
    //   #ffffff 100%,
    //   #d7e0ff 50%,
    //   #d64be2 0
    // ) !important;
    background: linear-gradient(
      104.66deg,
      #ffffff 13.45%,
      rgba(215, 224, 255, 0.5) 44.67%,
      rgba(214, 75, 226, 0) 95.57%
    );
  }
}

.ribbon-container {
  @apply absolute top-0;

  .ribbon {
    width: 4.938rem;
    height: 4.188rem;
  }

  .ribbon-big {
    width: 5.9rem;
    height: 5rem;
  }

  .ribbon-label-new {
    @apply absolute bottom-0 top-3 left-5 text-white font-medium leading-6;
    font-size: 0.9rem;
    transform: rotate(-40deg);
  }

  .ribbon-label-outlet {
    @apply absolute bottom-0 top-3 left-4 text-white font-medium leading-6;
    font-size: 0.9rem;
    transform: rotate(-40deg);
  }

  .ribbon-label-other {
    @apply absolute -bottom-1 top-4 left-4 text-white font-medium leading-6;
    font-size: 0.8rem;
    transform: rotate(-40deg);
  }
}

.app-rtl {
  .ribbon-container {
    .ribbon {
      transform: scaleX(-1);
    }
    .ribbon-label-new,
    .ribbon-label-outlet {
      transform: rotate(40deg);
    }
  }
}

.po-table {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .overflow-table-hidden {
    overflow-x: hidden !important;
  }

  &::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #6d6d6d;
  }

  @apply flex overflow-x-auto border border-gray-400 border-t-0 rounded;
  table {
    @apply w-full whitespace-normal;
    border-style: hidden;

    th,
    td {
      min-width: 5rem;
      max-width: 250px;

      &.fixed {
        @apply sticky;
      }
    }

    thead {
      tr {
        @apply h-10 bg-gray-200 text-white text-sm leading-normal whitespace-nowrap;
        background-color: $primary-color;
        letter-spacing: 0.5px;
        th {
          @apply p-4 bg-gray-200 font-medium py-0;
          background-color: $primary-color;

          .arrow {
            @apply flex flex-col justify-center items-center gap-1 p-1;

            .triangle {
              border-style: solid;
              height: 0px;
              width: 0px;

              &.up {
                border-color: transparent transparent rgba(255, 255, 255, 0.2);
                border-width: 0px 4px 4px;

                &.active {
                  border-color: transparent transparent rgba(255, 255, 255, 1);
                }
              }

              &.down {
                border-color: rgba(255, 255, 255, 0.2) transparent transparent;
                border-width: 4px 4px 0px;

                &.active {
                  border-color: rgba(255, 255, 255, 1) transparent transparent;
                }
              }
            }
          }
        }
      }
    }

    tbody {
      @apply text-sm font-normal;
      color: $primary-color;
      tr {
        &:nth-child(odd) {
          @apply border-b border-gray-200 bg-white;
          td.fixed {
            @apply bg-white;
          }
        }
        &:nth-child(even) {
          @apply border-b border-gray-200 bg-gray-100;
          td.fixed {
            @apply bg-gray-100;
          }
        }

        td {
          @apply p-3;
        }
      }
    }

    tfoot {
      @apply text-gray-800 text-sm font-light;
      tr {
        td {
          @apply p-3 border-t border-gray-500 bg-white;
        }
      }
    }
  }
  .pagination-bar,
  .no-pagination {
    @apply border-none;
  }

  .pagination {
    border-width: 0 0 1px 0;

    @apply rounded-none;
  }
}
.no-pagination {
  border-radius: 4px;
  &::-webkit-scrollbar-track {
    margin-left: 14px;
    margin-right: 14px;
  }
}
.pagination {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination-bar {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

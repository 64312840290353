.form,
form {
  label {
    @apply text-sm;
    color: $primary-color;
  }

  .input,
  input,
  textarea,
  select,
  .rmdp-container input {
    @apply border py-2.5 px-2 pl-3 mt-2 mb-3 w-full text-gray-700 leading-tight resize-none rounded-none border-gray-200;
  }

  .frm-phone-input {
    @apply mt-2 mb-3;
    .react-tel-input input {
      @apply border py-2.5 px-2 pl-12  w-full text-gray-700 h-10 leading-tight resize-none rounded-none border-gray-200;
    }
  }

  .rmdp-container {
    width: 100% !important;
    .rmdp-input {
      @apply h-auto;
    }
  }

  .frm-dropdown {
    @apply mt-2 mb-3 w-full;
  }

  .input,
  input[type="text"],
  textarea {
    @apply appearance-none;
  }

  select {
    @apply pr-4;
  }

  input,
  textarea,
  select,
  .frm-phone-input input,
  .rmdp-container input {
    &:disabled {
      @apply text-gray-800;
      background-color: $bright-gray !important;
    }
  }
}

.modal {
  @apply z-40;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;

    &.modal-content {
      transform: translateY(0);
    }
  }

  &.exit {
    opacity: 0;

    &.modal-content {
      transform: translateY(-200px);
    }
  }
  .modal-width-extra-large {
    min-width: 80rem !important;
  }
  .modal-width-large {
    min-width: 56rem !important;
  }
  .modal-width-medium {
    min-width: 40.25rem !important;
  }
  .modal-default-width {
    width: 50rem;
  }
  .rotate-icon {
    rotate: 180deg !important;
  }
}

.app-rtl ~ .modal-rtl {
  .arabic {
    flex-direction: row-reverse;
  }
  .modal-input {
    direction: rtl;
  }

  .modal-direction {
    direction: rtl;
  }
  .arabic-text-right {
    text-align: right;
  }
  .modal-header-rtl-btn {
    @apply ml-0;
  }

  .direction-ltr {
    direction: ltr !important;
  }
}

.po-vehicle-details {
  .card-detail {
    @apply m-2;
  }
  .ul-div {
    @apply flex mb-0 list-none flex-wrap pb-4 flex-row;
  }

  .li-div {
    @apply -mb-px  flex-auto text-center;

    .tab-name {
      @apply text-base py-3 px-1 block leading-normal;
    }
  }

  .tab-panel {
    overflow-y: scroll;
    height: 500px;
  }

  .textarea-notes {
    @apply border border-gray-400 rounded-sm w-full p-2 text-sm focus:border-gray-400 resize-none;
    height: 90px;
  }

  .list-container {
    display: flex;
    flex-direction: column;
  }

  .list-vehicle-img {
    width: 200px;
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  .bordered-button {
    @apply border border-gray-400 rounded-md;
    height: 38px;
    width: 102px;
  }

  .heading-report {
    @apply underline text-lg font-semibold;
  }

  .list-img-container {
    @apply md:flex-shrink-0 self-center bg-cover relative pb-2;
  }

  .QC-form-container {
    height: 568px;
    margin-left: 0;
  }

  @media (min-width: 640px) {
    .list-container {
      display: flex;
      flex-direction: row;
    }
  }

  @media (min-width: 768px) {
    .QC-form-container {
      height: 563px;
      margin-left: 15px;
    }
  }

  .heading-text {
    @apply default-color font-semibold;
  }

  .heading-container {
    @apply justify-between flex flex-row py-4;
  }

  .reject-status-container {
    border-radius: 4px;
    background-color: #ffe1e2;
    color: #dc323a;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .approve-status-container {
    @apply font-bold py-2 px-4 rounded;
    background-color: #eeffba;
    color: $kelly-green;
  }

  .status-dropdown {
    @apply px-2 border-gray-400 border ml-2;
  }

  .details-img {
    width: 250px;
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  .confirmation-modal-container {
    padding: 5% 3% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2%;
    background-color: white;
  }

  .cancel-button {
    @apply px-6 py-2 border-2 border-gray-300 text-gray-400 font-semibold text-base;
  }

  .ok-button {
    @apply object-fill bg-red-500 px-8 py-2 hover:bg-red-700 text-white font-semibold text-base;
  }
}

.spin-modal {
  @apply bg-transparent;

  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    @media (min-width: 640px) {
      height: 100%;
      min-height: 80vh;
    }
  }
}
.icon-360 {
  width: 195px;
  height: 100px;
}

.spin-background {
  background-color: #868383aa;
}

.spin-color {
  color: #fa4210;
}

.image-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 30;
}

.imageModalContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
}

.modal-img {
  width: 600px;
  aspect-ratio: 4/3;
  object-fit: cover;
}
@media (max-width: 768px) {
  .image-round {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .image-round {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@media (max-width: 768px) {
  .tile-round {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .tile-round {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.zoom-image-container {
  max-width: 80%;
  max-height: 90%;
  aspect-ratio: 16/9;
}

.aspect-16-9 {
  aspect-ratio: 16/9;
}

.aspect-4-3 {
  aspect-ratio: 4/3;
}

.bg-success {
  color: $success;
}

.document-heading-container {
  @apply flex border border-dashed rounded flex-wrap p-2 justify-between items-center;
  border-color: $primary-color;
  color: $primary-color;
  background-color: #eeeeee;
}

.document-title {
  @apply font-bold;
  color: $dark-grey-1;
}

.warning-status-container {
  @apply font-bold py-2 px-4 rounded;
  background-color: #ffeae1;
  color: $warning;
}

body {
  background: $body-background;
  font-family: $font-family;
  font-size: 15px;
  line-height: 1.5;
  color: $body-text;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;

  .app-container {
    min-height: calc(100vh - 20rem);
  }
}

.dropdown-container {
  height: 460px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 16px;
}

.dropdown-group-container {
  background-color: $white;
  padding-bottom: 32px;
}

.container-fluid {
  padding: 16px;
}

.container {
  padding: 8px;
  margin: auto;
}

@media (min-width: 920px) {
  .container {
    width: 1100px;
    margin: auto;
  }
}

.div-border {
  border: 1px solid $gray;
  max-width: 450px;
  display: flex;
  align-items: stretch;
  resize: both;
}

.div-align-strech {
  display: flex;
  align-items: center;
  resize: both;
  input {
    appearance: none;
    border-width: 1px;
    color: $charcoal;
    width: 45%;
    padding: 7%;
    margin: 0% 5%;
    text-align: center;
    margin: 5% 10%;
  }
}

.padding-top-8 {
  padding-top: 8px;
}

.padding-top-10 {
  padding-top: 10px;
}

.container-body {
  height: auto;
  padding-top: 16px;
  background-color: $white;
  padding-bottom: 32px;
  border-radius: 4px;
}

.dropdown-label {
  display: block;
  color: $primary-color;
  font-weight: 600;
  margin-bottom: 5px;
}
.dropdown-box {
  background-color: $alice-blue;
  border-radius: 4px;
}
.dropdown-options {
  width: 100%;
  background-color: $alice-blue;
  color: $black;
  border: none;
  outline: none;
}
.section-label {
  color: $cadmium-orange;
  font-weight: 600;
}
.dropdown-box select {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.vehicle-filter-section {
  width: 30%;
}
.color-box {
  @apply relative h-2 w-2 p-3 mr-3 mb-2 rounded-full border-2;
}
.slider-tooltip-button {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 17px;
  font-weight: 600;
  line-height: 1;
  color: $cadmium-orange;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border-radius: 6px;
  box-shadow: 0 0 4px $light-silver;
}

.rc-slider-handle {
  border: none !important;
  background-color: $cadmium-orange !important;
}

.configuration-tab {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 30px;
}
.footer-bottom {
  padding: 8px 0;
}
.body-type-div {
  width: 60px;
  height: 33px;
  background-color: $columbia-blue;
  padding: 5px;
  border-radius: 4px;
}

.configuration-tab p {
  color: $spanish-gray;
  @apply cursor-pointer hover:text-black;
  padding: 2% 2%;
}

.active-tab {
  border-top: 4px solid $granite-gray;
  color: $black !important;
  font-weight: 600;
}

.tab {
  border-top: 4px solid $transparent;
}

.page-header {
  @apply font-bold text-xl;
  color: $primary-color;
}

.view-button {
  text-decoration: underline;
  cursor: pointer;
  padding: 0px 3px;
}

.edit-button {
  text-decoration: underline;
  cursor: pointer;
  padding: 0px 3px;
}

.inspection-fee-input {
  border-radius: 4px;
  width: 100%;
  height: 30px;
  padding: 3px;
  border: 2px solid grey;
}

.btn-cancel {
  @apply bg-white hover:bg-red-700 hover:text-white text-red-500 border border-red-500 font-bold;
}

.underline-offset-3 {
  text-underline-offset: 3px;
}

.text-red {
  color: $primary-color;
}

.text-green {
  color: $kelly-green;
}

.bg-red {
  background-color: $primary-color;
}

.bg-pink {
  background-color: $misty-rose;
}

.btn-disabled {
  cursor: not-allowed !important;
  color: $white;
  background: $light-coral 0% 0% no-repeat padding-box;
  opacity: 1;
}

.counter-button {
  font-size: 25px;
}

.action-label {
  font-family: $font-family;
  @apply text-lg text-gray-500;
  text-align: start;
}

.configuration-edit-page-padding {
  padding: 3% 4% 0%;
}

.text-align-center {
  text-align: center;
}
.selected-color {
  top: 4px;
  position: absolute;
  left: 4px;
}
.vehicle-list-view {
  display: flex;
}
.vehicle-list-section {
  width: 70%;
}
@media (max-width: 1024px) {
  .vehicle-list-view {
    flex-direction: column;
  }
  .vehicle-filter-section {
    width: 100%;
  }
  .vehicle-list-section {
    width: 100%;
  }
}

.icons {
  @apply mr-2 text-sm ml-3 text-gray-400 flex;
}
.vehicle-description {
  color: $roman-silver;
  max-height: 45px;
  text-align: left;
}
.price {
  @apply text-lg font-semibold;
}
.vehicle-date-id {
  color: $independence;
  padding-top: 5px;
  font-size: 14px;
  font-weight: bold;
}
.vehicle-name {
  @apply tracking-wide text-gray-600 text-base font-semibold;
}
.listview-adminvehicle-img {
  width: 250px;
  height: 250px;
}
.listview-analystvehicle-img {
  width: 250px;
  height: 180px;
}
.btn-gray {
  @apply bg-gray-200 px-3 py-1 text-black font-semibold;
}

.analyst-button {
  background: $primary-color 0% 0% no-repeat padding-box;
  border-radius: 1px;
  opacity: 1;
  margin-right: 5%;
  padding: 1% 10%;
  button {
    text-align: left;
    font: normal normal normal 12px $font-family;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.package-header {
  background: $primary-color;
}
.table-font-color {
  color: #43474d;
}

.table-border {
  border-top-color: white;
  border-bottom-color: white;
}

.package-view-color {
  background: #d2bed4;
  :hover {
    background: #b799bb;
  }
}

.package-table-color {
  background: #f2f2f2;
}

.package-table-row-color {
  background: #eeeeee;
}
.package-list-text {
  color: $primary-color;
}

.analyst-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.common-tabs {
  display: flex;
  flex-wrap: wrap;
}

.common-tabs p {
  color: $roman-silver;
  @apply cursor-pointer hover:text-black;
  padding: 0% 2%;
  display: flex;
  align-items: center;
  width: 15%;
}

.common-active-tab {
  border-bottom: 2px solid #465166;
  color: $black !important;
  font-weight: 600;
}

.common-tabs-icon {
  width: 13%;
  margin-right: 10%;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.overlay-background {
  background: $white 0% 0% no-repeat padding-box;
  width: 75%;
  padding: 2% 3%;
  /* overflow-y: scroll;  */
}

.popover-details {
  padding: 5% 3% 15%;
  display: flex;
  align-items: center;
  width: auto;
  margin-top: 2%;
  background-color: $white;
}

.dashboard {
  .bg-green {
    background-color: $kiwi;
  }

  .bg-red {
    background-color: $primary-color;
  }

  .bg-blue {
    background-color: $medium-turquoise;
  }

  .bg-yellow {
    background-color: $satin-sheen-gold;
  }
}
.inspection-select-date {
  width: 145px;
  height: 40px;
  border: 1px solid $platinum;
  padding: 0px 0.5rem;
}
.price-text-color {
  color: $primary-color !important;
}
.km-color-danger {
  color: $primary-color !important;
  stroke: $primary-color !important;
  font-weight: 500;
  strokewidth: 1px;
}
.km-color-warning {
  color: $cadmium-orange !important;
  stroke: $cadmium-orange !important;
  font-weight: 500;
  strokewidth: 1px;
}
.km-color-normal {
  color: $kelly-green !important;
  stroke: $kelly-green !important;
  font-weight: 500;
  strokewidth: 1px;
}

.bg-light-blue {
  background-color: $medium-turquoise;
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: $red;
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  height: 10px;
  width: 10px;
  background-color: $green;
  border-radius: 50%;
  display: inline-block;
}

.dms-admin-tabs {
  display: flex;
  flex-wrap: wrap;
}

.dms-admin-tabs p {
  color: $roman-silver;
  @apply cursor-pointer hover:text-black;
  padding: 0% 1%;
  display: flex;
  align-items: center;
  width: 15%;
}

.dms-admin-active-tab {
  border-bottom: 2px solid $independence;
  color: $black !important;
  font-weight: 600;
}

.queryTable {
  border: 1px solid $black;
  div {
    padding: 0.5rem 2rem;
    font-size: 13px;
    text-align: center;
  }
}
.phoneInput {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;

  input {
    line-height: 1.25 !important;
    height: 100% !important;
    font-size: 16px !important;
    background: $white !important;
    width: 100% !important;
    outline: none !important;
    transition: box-shadow ease 0.25s, border-color ease 0.25s !important;
    border-radius: 0 !important;
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
    appearance: none;
    border-width: 1px;
    padding-right: 0.5rem;
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
    font-family: $font-family;
  }
}

.react-tel-input .special-label {
  display: none;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 8px;
}

.underline-grid-button {
  text-decoration: underline;
  cursor: pointer;
  padding: 0px 52px;
}

.back-button {
  height: 30px;
  width: 30px;
  color: $independence;
}
.default-color {
  color: $primary-color;
}

.default-border-color {
  border-color: $primary-color;
}
.m-logo {
  max-width: none !important;
  height: auto;
}

.report-link {
  color: $orioles-orange;
}

.rtl-text {
  direction: rtl;
}

.background-gradient-color {
  background: linear-gradient(90deg, #ff8300 0%, #de2a00 100%) !important;
}
.text-gradient {
  background: -webkit-linear-gradient(
    90deg,
    #ff8300 100%,
    #de2a00 100%
  ) !important;
  -webkit-background-clip: text !important;
  background-clip: inherit;
  -webkit-text-fill-color: transparent;
}

.border-primary-color {
  border-color: #de2a00;
}

.text-selection {
  --tw-text-opacity: 1;
  color: RGB(254 80 0 / var(--tw-text-opacity));
}
.transform-wrapper {
  width: 100% !important;
}
.image-pop-up {
  max-height: 90%;
  max-width: 80%;
  aspect-ratio: 16/9;
}
.image-contain {
  object-fit: contain !important;
}
@media (min-width: 640px) {
  .feedback-line-chart {
    @apply absolute flex-col gap-x-0 top-32 right-32;
  }
}

.notification-anchor-text,
.EmailEditorTheme__link {
  color: rgb(61, 61, 150);
  text-decoration: underline;
}

.text-primary {
  color: $primary-color;
}

.text-warning {
  color: $warning;
}

.filter-checkbox #checkbox-id {
  height: 15px;
  accent-color: $primary-color;
  width: 15px;
}

.filter-checkbox .checkbox-class {
  height: 15px;
  accent-color: $primary-color;
  width: 15px;
}

.arabic-font-family {
  font-family: $font-family-ar !important;
}

.z-9999 {
  z-index: 9999 !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap;
  display: inline-block;
}

.three-line-ellipsis {
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.tooltip {
  position: relative !important;
  display: inline-block !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: $primary-color;
  color: #fff;
  text-align: center;
  padding: 8px 16px;
  border-radius: 6px;
  overflow-wrap: break-word;
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 120%;
  left: 30%;
  margin-left: -60px;
}

.bg-primary {
  background-color: $primary-color;
}
.text-error {
  color: $error;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: $primary-color transparent transparent transparent;
}

.font-size-13 {
  font-size: 13px;
}

.package-text,
.emi-text {
  color: #43474d;
}

.cursor-not-allowed-imp {
  cursor: not-allowed !important;
}

.custom-tooltip {
  background: white;
  padding: 12px;
  border: 1px solid rgb(204, 204, 204);
  .value {
    color: #4b164b;
  }
}

.radio-class {
  accent-color: $primary-color;
}

.empty-button {
  min-width: 87px;
}

.focus-onClick {
  &:focus-visible {
    border: 1px solid #a4b3c5;
    box-shadow: 0 0 2px #a4b3c5;
    outline: none !important;
  }
}

.comment::placeholder {
  font-size: 0.9rem;
  color: $dark-grey-2;
}

.text-light {
  color: $dark-grey-2;
}

.app-find-vin {
  min-height: calc(100vh - 40px);

  table {
    tr {
      td {
        @apply border px-3 py-2;
        &:first-child {
          @apply font-bold;
        }
      }
    }
  }
}

ol.emi-list {
  list-style-type: decimal;
}

.emi-border {
  border: solid 1px !important;
  border-color: #43474d;
  border-radius: 0.25rem !important;
}

.emi-percentage {
  top: 30px;
  right: 1px;
  height: 38px;
  width: 39px;
}

.image-add-box {
  height: 150px;
}

.privacy-terms-link {
  color: $dark-grey-1 !important;
  line-height: 2;
  word-spacing: 1px;

  a {
    color: rgb(84, 110, 239);
    border-bottom: 1px solid rgb(84, 110, 239);
    font-weight: bold;
  }
  ol,
  ul {
    margin: revert;
    padding: revert;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}

.phone-input-select {
  width: 5rem !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 2.8rem !important;
}
.success-msg-color {
  color: #824c88;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiplight {
  visibility: hidden;
  color: $dark-grey-1;
  background-color: $white;
  padding: 8px;
  border-radius: 4px;
  overflow-wrap: break-word;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: 30%;
  margin-left: -60px;
  font-size: 12px;
  border: 1px solid $light-grey;
}

.tooltip:hover .tooltiplight {
  visibility: visible;
}

.tooltip .tooltiplight::before {
  content: " ";
  position: absolute;
  bottom: 100%;
  top: -8px;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent $white transparent;
}

.tooltip .tooltip-emi {
  visibility: hidden;
  background-color: $info;
  color: $primary-color;
  padding: 5px;
  border-radius: 6px;
  overflow-wrap: break-word;
  position: absolute;
  z-index: 1;
  width: 200px;
  top: 138%;
  left: 50%;
  margin-left: -181px;
}

.tooltip .tooltip-emi::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 91%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $primary-color transparent;
}

.tooltip:hover .tooltip-emi {
  visibility: visible;
}
.artifact-img {
  @apply w-full;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.banner-artifact-img {
  @apply w-full;
  aspect-ratio: 1920/501;
  object-fit: cover;
}
.aspect-banner {
  aspect-ratio: 1920/501;
}
.banner-artifact-img-mobile {
  @apply w-full;
  aspect-ratio: 390/220;
  object-fit: cover;
}
.aspect-banner-mobile {
  aspect-ratio: 390/220;
}
.img-artifact-div {
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}
.heading-div {
  min-width: 12rem;
}
.img-div {
  min-width: 16rem;
}

.icons-position-reports {
  display: flex;
  flex-direction: row;
  padding: 5px;
  font-weight: 550;
}

.pending-dealer {
  @apply w-full rounded-md border border-purple-900 h-24 mt-10;
  background: $btn-hover;
}
.color-border-box {
  position: relative;
  padding: 12px 20px;
  display: inline-block;
}

.color-border-box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 7px; /* control the border thickness */
  background: conic-gradient(
    from 1deg at 50% 50%,
    #de2a00 0deg,
    rgba(255, 131, 0, 0) 360deg
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.select-tag {
  font-size: 1rem;
}

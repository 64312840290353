.upload-vehicle-input {
  border-radius: 2px;
  width: 100%;
  height: 38px;
  padding: 3px;
  border: 1px solid gray;
}

.label-text {
  @apply text-gray-500 font-semibold;
  font-size: 18px;
}

.label-text-1 {
  @apply font-semibold;
  font-size: 18px;
  color: black;
}

.label-text-2 {
  @apply text-gray-500 font-semibold;
  font-size: 15px;
}

.label-text-3 {
  @apply text-gray-500;
  font-size: 15px;
}

.input-file-container {
  height: 13.2rem;
}

.btn-grey {
  @apply p-3 w-24 text-white text-sm mt-1 mr-5 cursor-pointer;
  background-color: rgb(168, 164, 164);
}

.input-file {
  display: none;
}

.inspection-modalBackground {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-popup-data {
  width: 50%;
  background-color: #fff;
  padding: 2rem;
  align-items: center;
}

.po-pie-chart {
  .recharts-wrapper {
    margin-left: -60px !important;
  }
  .recharts-legend-wrapper {
    position: absolute;
    width: auto;
    height: auto;
    top: 35px !important;
    margin-right: 20px;
  }
  .recharts-legend-item {
    margin-top: 20px;
  }
}

.pie-chart {
  .recharts-legend-wrapper {
    bottom: 65px !important;
  }
  .recharts-legend-item {
    margin-top: 20px;
  }
}

header.po-mobile-header {
  .m-logo-wrapper {
    .m-notification-holder {
      .name {
        color: #181818;
        font-weight: 700;
        overflow: hidden;
        overflow-wrap: normal;
        display: inline-block;
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .notification-link {
        color: #dc2c2b;
      }
    }
  }
  .nav-wrapper {
    nav.mobile-link-holder {
      @apply p-3;
      border: 1px solid #f5f5f5;
      a {
        @apply font-bold;
        &:hover {
          color: #dc323a;
        }
        &.is-active {
          color: #dc323a;
        }
      }
    }
  }
  .mobile-translator {
    display: block;
    width: 150px;
    padding: 0.275rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .logout-button {
    @apply px-5 py-1 border-2 bg-red-500 border-red-500 cursor-pointer box-content text-white;
  }
  .header-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 11;
  }
  .m-logo-mobile {
    width: 60px;
  }
  .language-switcher {
    font-size: 9px;
  }
}

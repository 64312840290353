:root {
  --rmdp-primary-red: #ea0034;
  --rmdp-secondary-red: #ad8787;
  --rmdp-shadow-red: #cfb6b6;
  --rmdp-today-red: #a37881;
  --rmdp-hover-red: #ff6687;
  --rmdp-deselect-red: #af1738;
}

.rmdp-wrapper {
  border-radius: 0 !important;
  box-shadow: 0 0 2px 0px #806a6a !important;
  .rmdp-arrow-container {
    background-color: $transparent;
    height: 30px;
    width: 30px;
    align-items: center;
    margin-top: -2px;
    padding-bottom: 5px;
  }
}

.red .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-red);
  box-shadow: 0 0 5px var(--rmdp-secondary-red);
}

.red .rmdp-panel-body li {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 2px var(--rmdp-secondary-red);
}

.red .rmdp-week-day {
  color: var(--rmdp-primary-red);
}

.red .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-red);
}

.red .rmdp-range {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-shadow-red);
}

.red .rmdp-arrow {
  border: solid var(--rmdp-primary-red);
  border-width: 0 2px 2px 0;
}

.red .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-secondary-red);
}

.red .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-red);
}

.red .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-red);
}

.red .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-red);
}

.red .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-shadow-red);
}

.red .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover-red) !important;
}

.red .b-deselect {
  color: var(--rmdp-deselect-red);
  background-color: white;
}

.red .rmdp-action-button {
  color: var(--rmdp-primary-red);
}

.red .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-red);
}

.red .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-red);
}

.footer {
  background: $primary-color;

  .footer-container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 1280px) {
    .footer-container {
      padding-top: 2.625rem;
      padding-bottom: 2.625rem;
      padding-left: 4.25rem;
      padding-right: 4.25rem;
    }
  }

  .internal-gap {
    gap: 1.438rem;
  }
}
.footer-bottom {
  padding: 8px 0;
  background-color: #1c051c;
}

.ar-footer-direction {
  direction: rtl;
}

.payment-icon {
  height: 60px !important;
  width: 100px !important;
}

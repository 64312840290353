.user-type-modal-container {
  padding: 5% 3% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2%;
  background-color: white;

  .user-type-select {
    height: 45px;
    border: 1px solid #999;
    font-size: 18px;
    border-radius: 1px;
    padding: 2%;
  }

  .ok-btn {
    @apply object-fill bg-red-500 px-8 py-2 hover:bg-red-700 text-white font-semibold text-base;
  }
}
